import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`

const StyledImage = styled(Img)`
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
`

const SquareImage = ({ image, children, className }) => {
  if (!image) return null
  return (
    <ImageContainer className={className}>
      <StyledImage
        fluid={{
          ...image.fluid,
          aspectRatio: 1 / 1,
        }}
      />
      {children}
    </ImageContainer>
  )
}

export default SquareImage
