import React from 'react'
import styled from 'styled-components'
import { getDurationStr } from 'src/helpers'
import format from 'date-fns/format'

const StyledPostInfo = styled.div`
  color: ${({ theme }) => theme.color.text}30;

  > :not(:last-child) {
    ::after {
      content: '';
      margin-left: 10px;
      margin-right: 10px;
      border-right: 1px solid ${({ theme }) => theme.color.text}30;
    }
  }
`

const PostInfo = ({
  publishedOn,
  tags,
  tagsHtml,
  episodeDuration,
  readingTime,
  className,
}) => (
  <StyledPostInfo className={className}>
    <span>{format(new Date(publishedOn), 'MMM d, yyyy')}</span>
    {tagsHtml ? (
      <span dangerouslySetInnerHTML={{ __html: tagsHtml }} />
    ) : (
      <>{tags && <span>{tags.join(', ')}</span>}</>
    )}
    {!!episodeDuration && <span>{getDurationStr(episodeDuration)}</span>}
    {readingTime && <span>{`${readingTime} read`}</span>}
  </StyledPostInfo>
)

export default PostInfo
